// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-advocates-avadani-js": () => import("./../../../src/pages/advocates/avadani.js" /* webpackChunkName: "component---src-pages-advocates-avadani-js" */),
  "component---src-pages-advocates-charishma-js": () => import("./../../../src/pages/advocates/charishma.js" /* webpackChunkName: "component---src-pages-advocates-charishma-js" */),
  "component---src-pages-advocates-divya-js": () => import("./../../../src/pages/advocates/divya.js" /* webpackChunkName: "component---src-pages-advocates-divya-js" */),
  "component---src-pages-advocates-divya-rani-js": () => import("./../../../src/pages/advocates/divyaRani.js" /* webpackChunkName: "component---src-pages-advocates-divya-rani-js" */),
  "component---src-pages-advocates-juber-js": () => import("./../../../src/pages/advocates/juber.js" /* webpackChunkName: "component---src-pages-advocates-juber-js" */),
  "component---src-pages-advocates-kruthika-js": () => import("./../../../src/pages/advocates/kruthika.js" /* webpackChunkName: "component---src-pages-advocates-kruthika-js" */),
  "component---src-pages-advocates-mrunalini-js": () => import("./../../../src/pages/advocates/mrunalini.js" /* webpackChunkName: "component---src-pages-advocates-mrunalini-js" */),
  "component---src-pages-advocates-nithin-js": () => import("./../../../src/pages/advocates/nithin.js" /* webpackChunkName: "component---src-pages-advocates-nithin-js" */),
  "component---src-pages-advocates-sanjay-js": () => import("./../../../src/pages/advocates/sanjay.js" /* webpackChunkName: "component---src-pages-advocates-sanjay-js" */),
  "component---src-pages-advocates-soubhagya-js": () => import("./../../../src/pages/advocates/soubhagya.js" /* webpackChunkName: "component---src-pages-advocates-soubhagya-js" */),
  "component---src-pages-advocates-srinidhi-js": () => import("./../../../src/pages/advocates/srinidhi.js" /* webpackChunkName: "component---src-pages-advocates-srinidhi-js" */),
  "component---src-pages-advocates-venkat-js": () => import("./../../../src/pages/advocates/venkat.js" /* webpackChunkName: "component---src-pages-advocates-venkat-js" */),
  "component---src-pages-advocates-vijay-js": () => import("./../../../src/pages/advocates/vijay.js" /* webpackChunkName: "component---src-pages-advocates-vijay-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

